<template>
  
    <el-dialog title=""
        :visible.sync="visible"
        width="1050px"
        :close-on-click-modal="false"
        :before-close="handleClose"
        :append-to-body="true"
        class="upload_box">


            <div class="wrap">
                <div class="title">选择你想添加的套餐</div>
                <div class="listWrap">
                    <!-- <el-table :data="tableData" style="width: 100%">
                        <el-table-column prop="date" label="名字" width="180">
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="220">
                            <template slot-scope="scope">
                                <el-button @click.stop="editBtn(scope.row)" type="text" size="small">
                                    编辑
                                </el-button>
                            </template>
                        </el-table-column>

                    </el-table> -->

                    <el-card v-for="(item, index) in tableData" :key="index" class="box-card">
                        <div class="cardWrap">
                            <div>{{ item?.goodsOrgVip?.name }}</div>
                            <el-button type="primary" @click="selFun(item)">选择</el-button>
                        </div>
                    </el-card>

                </div>
            </div>

    </el-dialog>
  
  </template>
  
  <script>
import { isNumber, isNumeric } from '@/utils/utils';

  export default {
    components: {  },
    // 数据
    data() {

      return {
        pageNumber:1,
        pageSize:20,
        total:0,
        tableData:[]
      }
    },
    props:{
        visible:Boolean,
        type:Number
    },
    watch: {
        visible(){
            if(this.visible){




            }
        }
    },
    computed:{
        
    },
    //  初始化
    mounted() {
      
    },
  
    methods: {

        init(){
            this.$https('queryGoods', {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                goodsType:this.type==1 ? 3 : 7,//星舰：goodsType:7    企业组织goodsType:3
            }).then(res => {

                res.data.records.forEach((val)=>{
                    Object.assign(val, val.goodsVip)
                })
                this.tableData = res.data.records


                this.total = parseInt(res.data.total)


            })
        },

        handleClose(){
            this.$emit('update:visible', false)
        },
        selFun(item){
            this.$emit("sel", item)
        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
.box-card{
    width: 300px;
    margin: 15px;
}
.title{
    text-align: center;
    font-size: 18px;
    padding-bottom: 42px;
}
.cardWrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.listWrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
  
  </style>
  
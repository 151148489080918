<template>
  
    <el-dialog title=""
        :visible.sync="visible"
        width="1000px"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="handleClose"
        class="upload_box">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules"   label-width="230px">

                <el-form-item label="名字" prop="name">
                    <el-input  v-model="ruleForm.name"  />
                </el-form-item>

<!-- 
                <el-form-item label="原价(元)" prop="originalPrice">
                    <el-input  v-model="ruleForm.originalPrice"  />
                </el-form-item> -->

                <el-form-item label="现价(元)" prop="realPrice">
                    <el-input  v-model="ruleForm.realPrice"  />
                </el-form-item>

                <!-- 
             
                对内组织版本：0 免费版本，1 体验版，2 企业标准版，3 企业旗舰版，4 团队版，5 企业豪华版，6 私有化部署
                星舰组织版本：0 免费版本，1 体验版，2 合作基础版，3 合作进阶版，4 合作高级版，5 高校校友版
             
                -->
                <el-form-item label="类型" prop="type" v-if="!isEdit">
                    <el-select v-model="ruleForm.type" style="width:100%" placeholder="请选择">
                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.label" :value="item.value" />
                    </el-select>
                </el-form-item>

                <!-- <div class="typeName" v-if="isEdit">
                    类型：{{ getTypeName(ruleForm.type) }}
                </div> -->

                <el-form-item label="年数" prop="years">
                    <el-input  v-model="ruleForm.years"  :disabled="!IsCanChangeTime" />
                </el-form-item>

                <el-form-item label="天数" prop="days">
                    <el-input  v-model="ruleForm.days"  :disabled="!IsCanChangeTime" />
                </el-form-item>

                <el-form-item label="算力" prop="walletBalance">
                    <el-input  v-model="ruleForm.walletBalance"/>
                    <span>注：{{ruleForm.walletBalance>0?ruleForm.walletBalance+'算力 = '+(ruleForm.walletBalance/10000).toFixed(2)+'元':"1算力 = 0.0001元"}}</span>
                </el-form-item>

                <div v-if="ruleForm.models">
                    <el-form-item label="" prop="years" v-for="(item, index) in modelList" :key="index">
                        <ModelSet :ref="`ModelSet${index}`" :hidePrice="true"></ModelSet>
                    </el-form-item>
                </div>
                
                <!-- //最低值是1 -->

                <el-form-item label="最多应用数量" prop="appNumLimit">
                    <el-input  v-model="ruleForm.appNumLimit"  />
                </el-form-item>
                
                <el-form-item label="最多成员数量" prop="memberNumLimt">
                    <el-input  v-model="ruleForm.memberNumLimt"  />
                </el-form-item>

                <el-form-item label="最多训练字数" prop="wordNumLimit">
                    <el-input  v-model="ruleForm.wordNumLimit"  />
                </el-form-item>

                <el-form-item label="一年内使用次数限制" prop="yearlyCountLimit">
                    <el-input  v-model="ruleForm.yearlyCountLimit"  />
                </el-form-item>

                <el-form-item label="平台分成比例" prop="percent">
                    <el-input  v-model="ruleForm.percent" class="input" />
                    <span class="percent">%</span>
                </el-form-item>

                <!-- <el-form-item label="是否启用" prop="status">
                    <el-select v-model="ruleForm.status" style="width:100%" placeholder="请选择" @change="selectChange">
                        <el-option label="启用" :value="1" />
                        <el-option label="禁用" :value="2" />
                    </el-select>
                </el-form-item> -->

            </el-form>
        <!-- 表单结束 -->
        <!--操作按钮-->
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary"  @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
  
  </template>
  
  <script>
import { isNumber, isNumeric } from '@/utils/utils';
import ModelSet from '../orgPruduct/ModelSet.vue';

  // 引入组件及方法
  // 引入组件
  export default {
    components: { ModelSet },
    // 数据
    data() {

        var nuberCheck = (rule, value, callback)=>{
            if (!value) {
                return callback(new Error('不能为空'));
            }

            if((value+'').indexOf(".") >= 0){
                return callback(new Error('不能有小数点'))
            }
            
            var bool = isNumeric(value)
            if(bool){
                var num = parseInt(value)
                if(num <= 0){
                    return callback(new Error('数值不能低于1'))
                }
                callback()
            }else{
                callback(new Error('只能输入数字'))
            }
        }

        var realPriceValidate = (rule, value, callback) => {
            const lenreg = /^(\d+.?)?\d{0,2}$/;
            if(value&&!lenreg.test(value)){
                callback(new Error('输入整数或保留两位小数'));
            }else{
                if(value<this.priceTotal){
                    callback(new Error('不得低于成本价的1.2倍：'+this.priceTotal+'元'));
                }
                callback();
            }
        }

        var walletBalanceValidate = (rule, value, callback) => {
            const lenreg = /^-?\d+$/;
            if(value&&!lenreg.test(value)){
                callback(new Error('输入整数'));
            }else{
                callback();
            }
        }

      return {
        ruleForm:{
            name:"",
            originalPrice:0,
            realPrice:0,
            walletBalance:0,
            type:1,
            years:1,
            appNumLimit:'',
            memberNumLimt:'',
            wordNumLimit:'',
            yearlyCountLimit:'',
            days:0,

            status:1,//状态：1 启用，2 禁用
            percent:0
        },
        rules: {
            name: [{ required: true, message: '不能为空', trigger: 'blur' }],
            originalPrice: [{ required: true, message: '不能为空', trigger: 'blur' }],
            realPrice: [{ required: true, message: '不能为空', trigger: 'blur' },
                // { validator: realPriceValidate, trigger: 'blur' }
            ],
            walletBalance: [{ required: true, message: '不能为空', trigger: 'blur' },
            { validator: walletBalanceValidate, trigger: 'blur' }],
            years: [{ required: true, message: '不能为空', trigger: 'blur' }],
            appNumLimit: [{ validator:nuberCheck , trigger: 'blur' }],
            memberNumLimt: [{ validator:nuberCheck , trigger: 'blur' }],
            wordNumLimit: [{ validator:nuberCheck , trigger: 'blur' }],
            yearlyCountLimit: [{ validator:nuberCheck , trigger: 'blur' }],

        },
        isEdit:false,
        extensionsCheckbox:[],
        modelList:[],
        proId:"",
        IsCanChangeTime:false

      }
    },
    props:{
        visible:Boolean,
        curGoodsType:Number,
    },
    watch: {
        visible(){
            if(this.visible){

            }
        }
    },
    computed:{
        typeList(){
            if(this.curGoodsType == 3){
                return [
                    {value:0, label:"免费版本"},
                    {value:1, label:"体验版"},
                    {value:2, label:"企业标准版"},
                    {value:3, label:"企业旗舰版"},
                    {value:4, label:"团队版"},
                    {value:5, label:"企业豪华版"},
                    {value:6, label:"私有化部署"}
                ]
            }else if(this.curGoodsType == 7){
                return [
                    {value:0, label:"免费版本"},
                    {value:1, label:"体验版"},
                    {value:2, label:"合作基础版"},
                    {value:3, label:"合作进阶版"},
                    {value:4, label:"合作高级版"},
                    {value:5, label:"高校校友版"}
                ]
            }
        }
    },
    //  初始化
    mounted() {
      
    },
  
    methods: {
        
        walletBalanceChange(e){
            this.priceTotal = ((Number(this.costPriceTotal)+(Number(e)/10000))*1.2).toFixed(2)
        },

        async start(obj, id, orgId, isEdit){
            this.isEdit = isEdit
            
            this.ruleForm = obj
            this.ruleForm.percent = obj.percent/1000*100
            
            this.proId = id
            this.orgId = orgId


            // if(!this.ruleForm.models){
            //     return
            // }

            await this.initModel()

            this.modelList.forEach((val, index)=>{
                var has = this.ruleForm.models.hasOwnProperty(val.modelValue)
                if(has){
                    val.checked = true
                    val.number = this.ruleForm.models[val.modelValue].number
                    val.price = this.ruleForm.models[val.modelValue].price/10000
                }
                this.$nextTick(()=>{
                    this.$refs[`ModelSet${index}`][0].init(val)
                })

            })
            
        },

        setIsCanChangeTime(bool){
            this.IsCanChangeTime = bool
        },
        
        async initModel(){

            await this.loadAllModel()

            this.modelList = []
            this.extensionsCheckbox.forEach((val)=>{
                var item = Object.assign(val)
                item.checked = false
                item.modelName = val.name
                item.modelValue = val.modelValue
                item.unitType = val.unitType

                this.modelList.push(item)
            })

            this.$nextTick(()=>{
                this.modelList.forEach((val, index)=>{
                    this.$refs[`ModelSet${index}`][0].init(val)
                })
            })

        },
        async loadAllModel(){
            
            var res = await this.$https('getAppModels', {})
            this.extensionsCheckbox = []
            res.data.forEach((val)=>{
                this.extensionsCheckbox.push({
                    label: parseInt(val.modelValue),
                    modelValue: parseInt(val.modelValue),
                    name: val.name,
                    unitType: val.unitType

                })
            })

        },
        createModelsParam(){
            var obj = {}

            this.modelList.forEach((val, index)=>{
                var item = this.$refs[`ModelSet${index}`][0].getInfo()
                if(item.checked){
                    obj[item.modelValue+""] = {modelValue:item.modelValue, number:item.number, price:item.price*10000}
                    if(obj[item.modelValue+""].price == undefined){
                        obj[item.modelValue+""].price = 0
                    }
                }
            })
            return obj

        },
        getTypeName(type){
            var arr = this.typeList
            var item = arr.find((val)=>{
                return val.value == type
            })
            if(item){
                return item.label
            }else{
                return "出错了"
            }
        },
        handleClose(){
            this.$emit('update:visible', false)
            this.$emit("close")
        },
        handleSubmit(){

            this.$refs['ruleForm'].validate(async (valid) => {
                if (valid) {
                    
                    var models = this.createModelsParam()

                    var param = {
                        goods:{
                            name:this.ruleForm.name,
                            originalPrice:this.ruleForm.realPrice * 100,
                            realPrice:this.ruleForm.realPrice * 100,
                            walletBalance :this.ruleForm.walletBalance,
                            type:this.ruleForm.type,
                            years:this.ruleForm.years,
                            days:this.ruleForm.days,
                            appNumLimit:this.ruleForm.appNumLimit,
                            memberNumLimt:this.ruleForm.memberNumLimt,
                            wordNumLimit:this.ruleForm.wordNumLimit,
                            yearlyCountLimit:this.ruleForm.yearlyCountLimit,
                            models:models,
                            percent:this.ruleForm.percent/100*1000
                        }
                    }

                    if(this.isEdit){
                        param.id = this.proId

                        this.$https('updateOrgGoods', param).then(res => {
                            this.handleClose()
                            this.$emit('ok')
                        })
                    }else{
                        param.goodsId = this.proId
                        param.orgId = this.orgId
                        this.$https('addOrgGoods', param).then(res => {
                            this.handleClose()
                            this.$emit('ok')
                        })
                    }
                    
                } else {
                    
                }
            });

        },
        selectChange(){
            this.$forceUpdate()
        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
.typeName{
    padding: 25px 0 25px 190px;
    
}
.input{
    width: 100px;
}
.percent{
    padding-left: 5px;
}
  
  </style>
  
<template>
  
    <el-dialog title=""
        :visible.sync="visible"
        width="1000px"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="handleClose"
        class="upload_box">

            <div class="wrap">
                <div class="title">{{ ruleForm.name }}</div>
                <!-- <div class="wrap2">
                    <div class="left">组织介绍：</div>
                    <div class="right">
                        {{ ruleForm?.summary }}
                    </div>
                </div> -->

                <div class="wrap2">
                    <div class="left">联系方式：</div>
                    <div class="right">
                        {{ ruleForm?.phoneNumber }}
                    </div>
                </div>

                <div class="wrap2">
                    <div class="left">组长名称：</div>
                    <div class="right">
                        {{ ruleForm?.leaderName }}
                    </div>
                </div>



                <div class="wrap2">
                    <div class="left">套餐：</div>
                    <div class="right">
                        {{ ruleForm?.orgGoods?.goodsOrgVip?.name }}
                    </div>
                </div>

                <div class="wrap2">
                    <div class="left">到期时间：</div>
                    <div class="right">
                        {{ ruleForm?.orgGoods?.expireTime }}
                    </div>
                </div>

                <div class="wrap2">
                    <div class="left">模型：</div>
                    <div class="right">
                        <div v-for="(item,index) in modelList" :key="index" class="modelLine">
                            <div class="t1">{{ nameFilter(item.modelValue)  }}：</div>
                            <div>{{ item.number }}</div>
                        </div>
                    </div>
                </div>

            </div>
            
    </el-dialog>
  
  </template>
  
  <script>
import { isNumber, isNumeric } from '@/utils/utils';
import ModelSet from '../orgPruduct/ModelSet.vue';

  // 引入组件及方法
  // 引入组件
  export default {
    components: { ModelSet },
    // 数据
    data() {




      return {
        modelList:[],
        extensionsCheckbox:[],
        ruleForm:{
        },
      }
    },
    props:{
        visible:Boolean,
    },
    watch: {
        visible(){
            if(this.visible){




            }
        }
    },
    computed:{
        typeList(){
            if(this.curGoodsType == 3){
                return [
                    {value:0, label:"免费版本"},
                    {value:1, label:"体验版"},
                    {value:2, label:"企业标准版"},
                    {value:3, label:"企业旗舰版"},
                    {value:4, label:"团队版"},
                    {value:5, label:"企业豪华版"},
                    {value:6, label:"私有化部署"}
                ]
            }else if(this.curGoodsType == 7){
                return [
                    {value:0, label:"免费版本"},
                    {value:1, label:"体验版"},
                    {value:2, label:"合作基础版"},
                    {value:3, label:"合作进阶版"},
                    {value:4, label:"合作高级版"},
                    {value:5, label:"高校校友版"}
                ]
            }
        }
    },
    //  初始化
    mounted() {
      
    },

  
    methods: {

        async start(obj){


            
                this.ruleForm = obj

                await this.loadAllModel()
                this.modelList = []
                
                for(var key in this.ruleForm.orgGoods.goodsOrgVip.models){
                    this.modelList.push(this.ruleForm.orgGoods.goodsOrgVip.models[key])
                }

            
        },
        handleClose(){
            this.$emit('update:visible', false)
            this.$emit("close")
        },
        async loadAllModel(){
            var res = await this.$https('getAppModels', {})
            console.log("所有模型",  res);
            this.extensionsCheckbox = []
            res.data.forEach((val)=>{
                this.extensionsCheckbox.push({
                    label: parseInt(val.modelValue),
                    modelValue: parseInt(val.modelValue),
                    name: val.name,
                    unitType: val.unitType
                })
            })
        },
        nameFilter(s){
            console.log("this.extensionsCheckbox",this.extensionsCheckbox);
            var item = this.extensionsCheckbox.find((val)=>{
                return val.modelValue == s
            })
            return item.name
        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
.wrap{
    margin: 33px;
}
.wrap2{
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    font-size: 15px;
}
.left{
    width: 200px;
    text-align: right;
    flex-shrink: 0;
    line-height: 25px;
    color: #949494;

}
.right{
    flex-grow: 1;
    padding-left: 8px;
    box-sizing: border-box;
    line-height: 25px;
    color: #666666;

}
.modelLine{
    display: flex;
    flex-direction: row;
    .t1{
        width: 120px;
        text-align: right;
    }
}
.title{
    text-align: center;
    font-size: 18px;
}
  </style>
  
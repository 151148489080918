<template>
  
    <el-dialog title=""
        :visible.sync="visible"
        width="1000px"
        :close-on-click-modal="false"
        :before-close="handleClose"
        :append-to-body="true"
        class="upload_box">


            <div class="wrap">
                <el-button type="primary" class="addBtn" @click="addProductClick">添加套餐</el-button>

                <el-table :data="dataList" stripe style="width: 100%" border>
                    <el-table-column prop="date" label="套餐名">
                        <template slot-scope="scope">
                            <div>{{ scope.row.goodsOrgVip.name }}<span v-if="isCurGoods(scope.row)" class="curText">(当前套餐)</span></div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="date" label="开始时间">
                        <template slot-scope="scope">
                            <div>{{ scope.row.startTime }}</div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="date" label="到期时间">
                        <template slot-scope="scope">
                            <div>{{ scope.row.expireTime }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="220">
                        <template slot-scope="scope">

                            <el-button @click.stop="editBtn(scope.row)" type="text" size="small">
                                修改套餐
                            </el-button>
                            <!-- <el-button @click.stop="detailBtn(scope.row)" type="text" size="small">
                                详情
                            </el-button> -->

                        </template>
                    </el-table-column>
                </el-table>
            </div>

            
            <ProductSelDialog ref="ProductSelDialog" :visible.sync="ProductSelDialogVisible" :type="type" @sel="productSelCallback"></ProductSelDialog>
            
            <EditProduct ref="EditProductRef" :visible.sync="EditProductVisible" @ok="editFinish" @close="closeEditProduct"></EditProduct>
    </el-dialog>
  
  </template>
  
  <script>
import { isNumber, isNumeric } from '@/utils/utils';
import ProductSelDialog from './ProductSelDialog.vue';
import EditProduct from './EditProduct.vue';

  export default {
    components: { ProductSelDialog, EditProduct },
    // 数据
    data() {

      return {
        orgId:"",
        type:1,//1是企业组织
        dataList:[],
        ProductSelDialogVisible:false,
        EditProductVisible:false
        
      }
    },
    props:{
        visible:Boolean
    },
    watch: {
        visible(){
            if(this.visible){




            }
        }
    },
    computed:{
        
    },
    //  初始化
    mounted() {
      
    },
  
    methods: {

        init(orgId, type){
            this.orgId = orgId
            this.type = type
            this.loadData()
        },

        async loadData(){
            var res = await this.$https.get('getOrgGoodsList', {orgId: this.orgId, onlyCurrent:false})

            // "goodsOrgVip" 星舰内部套餐 和 组织内部套餐

            this.dataList = res.data

        },

        handleClose(){
            this.$emit('update:visible', false)
            this.$emit("close")
        },
        editBtn(item){
            this.EditProductVisible = true
            this.$nextTick(()=>{
                
                item.goodsOrgVip.realPrice = item.goodsOrgVip.realPrice/100

                this.$refs.EditProductRef.start(item.goodsOrgVip, item.id, this.orgId, true)
                if(this.dataList.indexOf(item) == this.dataList.length-1){
                    this.$refs.EditProductRef.setIsCanChangeTime(true)
                }else{
                    this.$refs.EditProductRef.setIsCanChangeTime(false)
                }
            })
        },
        detailBtn(){

        },
        addProductClick(){
            if(this.dataList && this.dataList.length >= 2){
                this.$message.warning('最多只能有2个套餐')
                return
            }
            this.ProductSelDialogVisible = true
            this.$nextTick(()=>{
                this.$refs.ProductSelDialog.init()
            })
        },
        //选择一个套餐后
        productSelCallback(item){
            this.EditProductVisible = true
            this.ProductSelDialogVisible = false

            this.$nextTick(()=>{
                
                item.goodsOrgVip.realPrice = item.goodsOrgVip.realPrice/100

                this.$refs.EditProductRef.start(item.goodsOrgVip, item.id, this.orgId, false)
            })
        },
        editFinish(){
            this.loadData()
        },
        closeEditProduct(){
            this.loadData()
        },
        isCanChange(item){
            if(this.dataList.indexOf(item) == this.dataList.length-1){
                return true
            }else{
                return false
            }
        },
        isCurGoods(item){
            // if(this.dataList.indexOf(item) == this.dataList.length-1){
            let startTime = new Date(item.startTime).getTime();
            let expireTime = new Date(item.expireTime).getTime();
            let nowTime = Date.now()
            if(startTime<nowTime&&expireTime>nowTime){
                return true
            }else{
                return false
            }
        }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .wrap{
    padding: 50px;
  }
  .addBtn{
    margin-bottom: 10px;
  }
  .curText{
    color: #f22;
  }
  
  </style>
  
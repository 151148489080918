<template>
    <div>
        <el-card class="box-card">
            <el-form :inline="true" :model="queryParams" class="demo-form-inline">
                <el-form-item label="组织名称">
                    <el-input v-model="queryParams.name" placeholder="组织名称" :clearable="true"></el-input>
                </el-form-item>
                <el-form-item label="组织号">
                    <el-input v-model="queryParams.tag" placeholder="组织号" :clearable="true"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <br>

        <el-table :data="tableData" style="width: 100%" border>
            <el-table-column
                prop="name"
                label="组织名称">
                <template slot-scope="scope">
                    <div class="infoWrap">
                        <img v-if="scope.row.icon" :src="scope.row.icon" class="avatar">
                        <div v-if="!scope.row.icon" class="defaultImg"></div>
                        <div class="orgName">{{ scope.row.name }}</div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                prop="tag"
                label="组织号"
                >
            </el-table-column>

            <el-table-column
                label="创建用户"
                prop="username">
                    <template slot-scope="scope">
                        <div class="user-info">
                            <img class="avatar-box" :src="scope.row.avatar"/> 
                            <div class="username-box">{{ scope.row.username }}</div>
                            <div>{{ scope.row.mobile }}</div>
                        </div>
                    </template>
                </el-table-column>

            <!-- <el-table-column
                prop="type"
                label="类型">
                <template slot-scope="scope">
                    <div>{{ scope.row.type==1?'企业组织':'星舰' }}</div>
                </template>
            </el-table-column> -->

            <el-table-column
                prop="type"
                label="分成比例">
                <template slot-scope="scope">
                    <div v-if="scope.row?.orgGoods?.goodsOrgVip.percent">
                        {{ scope.row?.orgGoods?.goodsOrgVip?.percent/10 }}%
                    </div>
                </template>
            </el-table-column>
            
            <el-table-column
                prop="status"
                label="类型/套餐">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.type==1?'企业组织':'星舰' }}
                        {{ scope.row?.orgGoods?.goodsOrgVip?.name?'：'+scope.row.orgGoods.goodsOrgVip.name:'' }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                fixed="right"
                label="操作"
                width="220">
                <template slot-scope="scope">
                    <el-button @click.stop="editBtn(scope.row)" type="text" size="small">
                        套餐
                    </el-button>
                    <el-button @click.stop="detailBtn(scope.row)" type="text" size="small">
                        组织详情
                    </el-button>
                </template>
            </el-table-column>

        </el-table>

        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNumber"
            :page-sizes="[20, 50, 100, 150]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>

        <orgProductDialog ref="orgProductDialogRef" :visible.sync="orgProductDialogVisible" @close="orgProductDialogClose"></orgProductDialog>
        <orgDetail ref="orgDetailRef" :visible.sync="orgDetailVisible"></orgDetail>
        
    </div>
</template>
  
<script>
import orgDetail from './orgDetail.vue';
import orgProductDialog from './orgProductDialog.vue';

  export default {
    components: { orgProductDialog,orgDetail },
    data() {
      return {
        pageNumber:1,
        pageSize:20,
        queryParams:{
            name:"",
            tag:""
        },
        tableData:[],
        total:0,
        orgProductDialogVisible:false,
        orgDetailVisible:false
      };
    },
    async mounted() {

        this.getData()

    },
    methods: {
        getData() {
            
            this.$https('queryOrganization', {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                ...this.queryParams
            }).then(res => {

                res.data.records.forEach((val)=>{
                    
                })
                this.tableData = res.data.records


                this.total = parseInt(res.data.total)

            })
        },
        editBtn(item){
            this.orgProductDialogVisible = true
            this.$nextTick(()=>{
                this.$refs.orgProductDialogRef.init(item.id, item.type)
            })
        },
        detailBtn(item){
            this.orgDetailVisible = true
            this.$nextTick(()=>{
                this.$refs.orgDetailRef.start(item)
            })
        },
        handleSizeChange(size) {
            this.pageSize = size
            this.getData()
        },
        handleCurrentChange(num) {
            this.pageNumber = num
            this.getData()
        },
        onSubmit() {
            this.pageNumber = 1
            this.getData()
        },
        orgProductDialogClose(){
            this.getData()
        }

    },
  };
</script>
  
<style scoped lang="scss" >
.infoWrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    .avatar{
        width: 40px;
        height: 40px;
        border-radius: 60px;
    }
    .defaultImg{
        width: 40px;
        height: 40px;
        border-radius: 60px;
        background: #e5e5e5;
    }
    .orgName{
        padding-left: 10px;
    }
}
.user-info {
    display: flex;
    align-items: center;
    .avatar-box {
        width: 50px;
    }
    .username-box {
        margin: 0 5px;
    }
}
</style>
  